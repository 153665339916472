import { Link } from '@loveholidays/design-system';
import React from 'react';

import { FooterContent } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { List } from '@UX/List/List';
import { pageContainerStyles } from '@UX/Page/LegacyPageContainer';

interface FooterSectionProps extends ClassNameProps {
  links: FooterContent['links'];
}

export const FooterSection: React.FC<FooterSectionProps> = ({ links = [], className }) => (
  <List
    variant="none"
    verticalSpacing="large"
    columns={[2, 2, 4]}
    className={className}
    sx={{
      ...pageContainerStyles(true),
      padding: [0, 'xs'],
      backgroundColor: 'white',
      color: 'textDefault',
      '> li': {
        paddingLeft: [0, 'xs'],
      },
    }}
  >
    {links.map(
      ({ label, url, rel }, i) =>
        !!url && (
          <li key={i}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <Link
              href={url}
              target="_blank"
              // add `noopener` for external links
              rel={`${rel} ${!url?.startsWith('/') ? 'noopener' : ''}`}
              sx={{
                color: 'textDimmedheavy',
                '&:hover': {
                  color: 'textDefault',
                },
                '&:visited': {
                  color: 'textDimmedheavy',
                },
              }}
            >
              {label}
            </Link>
          </li>
        ),
    )}
  </List>
);
